import React from "react"
import { Link } from "gatsby"
// import PropTypes from "prop-types"
import Transition from '../components/transitions'
import Search from '../components/SearchContainer'
import "aos/dist/aos.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./layout.css"
import "./layout-responsive.css"
import AOS  from 'aos'
import { FaFacebook,FaInstagram } from "react-icons/fa";

const defaultState = {
  currentBG: "",
  height: 0,
  scrollStateClass: ""
}


export const LayoutContext = React.createContext(defaultState)
export default class Layout extends React.Component{
  
  constructor(props){
    super(props);
    this.updateHeight = this.updateHeight.bind(this);
  }

  state = {
    currentBG: "recettes",
    height: 0,
    scrollStateClass: "unshrinked-menu",
    newPage: false
   }

  componentDidMount(){
    AOS.init({
      duration : 1500
    })
    this.SetBackgroundOnLoad();
    this.updateHeight();
    window.addEventListener('scroll', this.handleScroll);
  }


  componentDidUpdate() {
    this.updateHeight();
  }


   handleScroll= () => {
    let scroll = this.state.scrollStateClass
     if((scroll === "unshrinked-menu" || scroll === "") && window.pageYOffset >= 80)
     {
        this.setState({ scrollStateClass: "shrinked-menu" })
      }

    if(scroll === "shrinked-menu" && window.pageYOffset <= 80)
     {
        this.setState({ scrollStateClass: "unshrinked-menu" })
      }
  }

   updateHeight() {
      if (this.state.height !== this.div.clientHeight)
      {
        let scrollClass = "unshrinked-menu";
        // if(window.innerHeight > window.innerHeight)
        //   scrollClass = "shrinked-menu";
        
        this.setState({ height: this.div.clientHeight, scrollStateClass: scrollClass });
    }
  }

  handleClickSection(clickSection) {
    let bg = this.state.currentBG
    if(bg !== clickSection)
    {
      this.setState({currentBG: clickSection, scrollStateClass: "unshrinked-menu", height: 0});
    }

  };

  
  
  SetBackgroundOnLoad()
  {
    let url = window.location.pathname;
    let bg = "recettes";
    let scrollClass = "unshrinked-menu";

    if(url.startsWith('/recettes')){
      bg = "recettes";
      scrollClass = "shrinked-menu";
    }

    if(url.startsWith('/articles')){
      bg = "hs";
      scrollClass = "shrinked-menu";
    }

    if(url.startsWith('/anarchy'))
      bg = "anarchy";

      if(url.startsWith('/mentions'))
      bg = "mentions";

    if(url.startsWith('/horssujet'))
      bg = "hs";

    if(url.startsWith('/contact'))
      bg = "contact";

    this.setState({ currentBG: bg, scrollStateClass: scrollClass });
      
  }


  render(){
    const { children, location} = this.props
    const { currentBG, scrollStateClass } = this.state
    
    
    return (
      <div >
        <div className={"bg " + currentBG}></div>
        <div className={"bgmain " + currentBG} ref='bgmain' id='bgmain'>
          <div id="logo" className={"logo logo-" + scrollStateClass}></div>
            <header >
              <nav id="mainnav" className={"nav-" + scrollStateClass + " " + scrollStateClass}> 
                <ul id="mainul" className={scrollStateClass}>
                <li className={"link-contact " + scrollStateClass}><Link className="menu1" activeClassName="active" to='/contact' onClick={() => this.handleClickSection("contact")}>
                  <span className={scrollStateClass + "contact"}>Contact</span>
                  <div className={scrollStateClass + "iconecontact"}></div>
                </Link></li>
                <li className={"link-hs " + scrollStateClass}><Link className="menu2" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: "menu2 active" } : null} to='/horssujet' onClick={() => this.handleClickSection("hs")}>Hors Sujet</Link></li>
                <li className={"link-recettes " + scrollStateClass}><Link className="menu3" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: "menu3 active" } : null} to='/recettes' onClick={() => this.handleClickSection("recettes")}>Mes Recettes</Link></li>
                <li className={"link-anarchy " + scrollStateClass}><Link className="menu4" activeClassName="active" to='/' onClick={() => this.handleClickSection("anarchy")}>Ateliers</Link></li>
                    </ul>
              </nav>
              {/* <div className="nav-contact">
                <div className="social-buttons">
                {/* activeClassName="active"  onClick={() => this.handleClickSection("contact")}
                  <Link to="/" alt="Contact" className="social-buttons__button social-button social-button--mail contact-button " 
                   aria-label="MailMe"> 
                    <span className="social-button__inner">
                      <div className="contact-text">
                        <span >Contact</span>
                      </div>
                    </span>
                  </Link>
                </div>
              </div>  */}
              
              
            </header>

            <div className="transition transition-recettes"></div>
            <div className="transition transition2 transition-hs"></div>
            <div className="transition transition3 transition-anarchy"></div>
            <div className="transition transition4 transition-contact"></div>
            <div className="transition transition5 transition-mentions"></div>
            


            <section className="menu menu--circle">
              <input type="checkbox" id="menu__active"/>
              <label htmlFor="menu__active" className="menu__active">
                <div className="menu__toggle">
                  <div className="icon">
                    <div className="hamburger"></div>
                  </div>
                </div>
                <input type="radio" name="arrow--up" id="degree--up-0"/>
                <input type="radio" name="arrow--up" id="degree--up-1" />
                <input type="radio" name="arrow--up" id="degree--up-2" />
                <div className="menu__listings">
                  <ul className="circle">
                    <li>
                      <div className="placeholder">
                        <div className="upside">
                        <Link to='/bases' onClick={() => this.handleClickSection("hs")}>
                        <div rel="Secret" className="myFB-logo-mobile"></div>
                        </Link>
                         
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="placeholder">
                        <div className="upside">
                        <Link to='/decouvertes' onClick={() => this.handleClickSection("hs")}>
                          <div rel="Decouvertes" className="myInsta-logo-mobile"></div>
                        </Link>
                        
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="placeholder">
                        <div className="upside">
                        
                        </div>
                      </div>
                    </li>
                    
                   
                    <li>
                      <div className="placeholder">
                        <div className="upside">
                        
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="placeholder">
                        <div className="upside">
                        <Link to="/mentions" onClick={() => this.handleClickSection("mentions")}><div rel="Mentions Légales" className="myML-logo"></div></Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <ul className="social-icons">
                    <li><a href="https://www.facebook.com/Anarchy.InThe.KITCH" alt="facebook" target="_blank" rel="noreferrer" className="facebook"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/anarchy.inthe.kitchen" alt="instagram" target="_blank" rel="noreferrer" className="youtube"><i className="fa fa-instagram"></i></a></li>
                </ul>

              </label>
            </section>

            <section className={"left-panel left-panel-" + scrollStateClass}>
              <div className="recherche-zone">
                <h3>Recherche</h3>
                <Search></Search>
              </div>
              <div className="secret-zone">
                <Link id="link-secret" className="left-link" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: " active" } : null} to='/bases' onClick={() => this.handleClickSection("hs")}>
                  LES BASES POUR LES NULS
                </Link>
                
              </div>
              <div className="decouverte-zone">
                <Link id="link-decouverte" className="left-link" getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: " active" } : null} to='/decouvertes' onClick={() => this.handleClickSection("hs")}>
                  MES DECOUVERTES
                </Link>
                
              </div>
              <div className="partenaires-zone">
                
                
                <div className="grid">
                <a href="https://www.facebook.com/Anarchy.InThe.KITCH" alt="facebook" target="_blank" rel="noreferrer"> 
                    <figure className="effect-milo">
                    <div className="background myfacebook"/>
                      <figcaption>
                      {/* <h2>Suivez nous sur&nbsp;<span>Facebook</span></h2> */}
                      <div className="myFB-logo"></div>
                      </figcaption>			
                    </figure>
                  </a>
                </div>
                
                
                <div className="grid">
                  <a href="https://www.instagram.com/anarchy.inthe.kitchen" alt="instagram" target="_blank" rel="noreferrer"> 
                    <figure className="effect-milo">
                        <div className="background myInsta"/>
                          <figcaption>
                          {/* <h2>Suivez nous sur&nbsp;<span>Instagram</span></h2> */}
                          <div className="myInsta-logo"></div>
                          </figcaption>		
                    </figure>
                  </a>
                </div>
               
              </div>


              <div className="mentions-zone" data-aos="fade-up">
                <Link to="/mentions" alt="mentions" className="mention-button " activeClassName="active" 
                  onClick={() => this.handleClickSection("mentions")} > 
                    Mentions Légales
                </Link> 
                <a href="https://www.ydil.paris" alt="mentions" target="_blank" className="ydil-button "  rel="noreferrer"> 
                    un site d'Ydil.paris
                </a> 
              </div>
            </section>

            

            <Transition location = {location}>
              <main ref={ div => { this.div = div; } }>
                {children}
              </main>
            </Transition>
            
          </div>
      </div>
      )
    }
  }
  
  // export default ({ children, location}) => {
  //       return <Layout location={location} >{children}</Layout>
  // }


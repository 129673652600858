// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-js": () => import("./../../../src/pages/mentions.js" /* webpackChunkName: "component---src-pages-mentions-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-templates-decouverte-js": () => import("./../../../src/templates/decouverte.js" /* webpackChunkName: "component---src-templates-decouverte-js" */),
  "component---src-templates-hs-list-js": () => import("./../../../src/templates/hs-list.js" /* webpackChunkName: "component---src-templates-hs-list-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-recipe-js": () => import("./../../../src/templates/post-recipe.js" /* webpackChunkName: "component---src-templates-post-recipe-js" */),
  "component---src-templates-recipe-list-js": () => import("./../../../src/templates/recipe-list.js" /* webpackChunkName: "component---src-templates-recipe-list-js" */),
  "component---src-templates-secret-js": () => import("./../../../src/templates/secret.js" /* webpackChunkName: "component---src-templates-secret-js" */)
}


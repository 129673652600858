import React, { useState,createRef } from "react"
import { Link, useStaticQuery,graphql } from "gatsby"
import useClickOutside from "./use-click-outside"

export const Search = () => {

  const  data  = useStaticQuery(graphql`
  query {
    allWordpressPost(
      sort: { fields: date, order: DESC }
        ){
        edges {
            node {
                title
                excerpt
                slug
                categories
                date(formatString: "DD/MM/YYYY")
            }
        }
    }
  }
`)

  const allPosts = data.allWordpressPost.edges
  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
    hideResult: "hide",
  })
  
  const handleInputChange = event => {
    search(event.target.value);
  }

  const rootRef = createRef()
  useClickOutside(rootRef, () => handleInputLooseFocus())

  function search(queryString){
    const query = queryString
      const posts = allPosts || []
      const hideResult = "";
      const filteredData = posts.filter(post => {
        const { title } = post.node
        return (
          
          title.toLowerCase().includes(query.toLowerCase()) 
          // ||
          // (tags &&
          //   tags
          //     .join("")
          //     .toLowerCase()
          //     .includes(query.toLowerCase()))
        )
      })
      setState({
        query,
        filteredData,
        hideResult
      })
  }

 
  function handleInputLooseFocus() {
    const hideResult = "hide";
    setState({
      hideResult
    })
  }

  const handleInputFocus = event => {
    // show if data
    const query = event.target.value
    if(query !== ""){
      search(event.target.value);
    }
  }

  const { filteredData, query, hideResult } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : []


  return (
    <>
      <div className="wrap">
        <div className="search">
            <input type="text" className="searchTerm" placeholder="J'ai faim !" onChange={handleInputChange} onFocus={handleInputFocus} />
            <button type="submit" className="searchButton">
              <i className="fa fa-search"></i>
            </button>
        </div>
      </div>
      
      <div className={"searchResults "+ hideResult} >
        {posts.map(({ node }) => {
          return (
            <div>
              <Link onClick={handleInputLooseFocus} to={ (node.categories.indexOf(244704) !== -1 ? "../../recettes/" : "../../horssujet/") + node.slug}>
              <h2 dangerouslySetInnerHTML={{ __html: node.title }}></h2>
              <div className="encart" dangerouslySetInnerHTML={{ __html: node.excerpt }}></div>
              <div className="encart-after"></div>
              </Link>
              <hr></hr>
            </div>
          )
        })}
      </div>
    </>
  )
}
export default Search
